import { HttpClient, HttpClientJsonpModule, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { EventInput, EventSourceInput } from '@fullcalendar/angular';
import {Component,OnInit,OnChanges} from '@angular/core';
//import { CalendarEvent } from '../domain/calendar';
//import { Calendar } from 'primeng/calendar';









@Injectable()
export class CalendarEventTypesService implements OnInit {

    events1: EventSourceInput;

   
   
   
    constructor(private http: HttpClient) { }
   
   

    ngOnInit() {
   
    };


    

     async getEventTypes( )  {
          
      return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Calendar/eventtypes.php')
      .toPromise()
      .then(res => <any[]>res.data)
     .then(data => { return data;} )
      };
    
       
      
 
    async getTypebyID(eventid : string)  {
      return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Calendar/eventtypes.php?eventid=${eventid}')
      .toPromise()
      .then(res => <any[]>res.data)
     .then(data => { return data;} )
      };
    
       
  };
  

