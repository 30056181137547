
<div class="home"  >
   
    <div class="introduction" [ngClass]="{'introduction-dark': config.dark}" >
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
        <div class="feature-card" >
             <h3><span class="introduction-promo">Rock Hill Wrestling Academy</span></h3>
              <div class="introduction-title">Preparing Wrestlers for today, middle school, high school, </div>
              <div class="introduction-subtitle">...and BEYOND!</div>
              <a class="action-button"  [routerLink]="['/setup']"> REGISTER NOW   </a>
        </div>
    </div>
        </div>
        
        

        
    </div>
    <div class="content-section implementation">
       
    <p-card header="Why Rock Hill Wrestling Academy?" [style]="{'margin-bottom': '2em'}">
        <div class="p-card-content" >
            <p>Rock Hill Wrestling Academy using a system from the first day of practice through the entire season.  Rock Hill Wrestling Academy is a system of techniques 
            grouped together to teach offense and defense through repititon and live wrestling.  Depending of the difficulty level of the techniques, we may spend up to two weeks 
            on those grouped techniques, with reviews, and muscle memory refinforcements throughout the season.</p>
        </div>
    </p-card>

    <p-card header ="Wrestling teaches us life lessons!" [style]="{'margin-bottom': '2em'}">
        <div class="p-card-content" >
    <p-carousel [value]="homepagefeatures" styleClass="custom-carousel" [numVisible]="2" [numScroll]="1" [circular]="true" [autoplayInterval]="10000" [responsiveOptions]="responsiveOptions">
       
        <ng-template let-homepagefeature pTemplate="item">
            
            <div class="product-item">
                <div class="product-item-content"  >
                    
                    <h4 class="p-mb-1">{{homepagefeature.name}}</h4>
                   
                       
                                <img src="{{homepagefeature.imageurl}}" [alt]="homepagefeature.name" class="product-image" />
                               
                                <p   >{{homepagefeature.description}}</p>
                            
                            
                   
                   
                </div>
            </div>
        
        </ng-template>

    </p-carousel>
    </div>
        </p-card>

        <p-card header ="Today's Practice"  [style]="{'width': '50%', 'margin': 'auto', 'margin-bottom': '2em'}">
            
            <div class="p-card-content">
               
               <p>6:00 - 6:10 Warmups<br>
               6:10 - 6:15 Mind Set<br>
               6:15 - 6:20 Stance and Motion<br>
               6:20 - 6:30 Quickness drills/Chain Drills<br></p>

               <h6>Offense</h6>
               <p>
               6:30 - 6:40 Technique 1 <a>Video: Go behinds: Sprawl, pull and cross</a><br>
               6:40 - 6:50 Technique 2 <a>Video: Go behinds: Sprawl, drag through</a><br>
               6:50 - 7:00 Technique 3 <a>Video: Go behinds: Sprawl, front headlock to head in hole</a><br>
            </p>
               
               <h6>Defense</h6>
               <p>
               7:00 - 7:10 Technique 1 <a>Video: Good Position on failed shot, circle up.</a><br>
               7:10 - 7:20 Technique 2 <a>Video: Go behinds: Sprawl, drag through</a><br>
               7:20 - 7:30 Technique 3 <a>Video: Control Elbow and fingers, circle out.</a><br>

               7:30 - 7:45 Sparring (Review techniques of your choice) <br>
               7:45 - 8:00 LIVE!! </p>

            </div> 
           
        </p-card>
    
    </div>  
    
       
          
            
        


    
    

    
</div>
