import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Homepagefeature } from '../domain/homepagefeature';

@Injectable()
export class HomePageFeatureService {

    id?:string;
    name?:string;
    imageurl?:string;
    description?:string;


  

    constructor(private http: HttpClient) { }

    getFeatures() {
        return this.http.get<any>('assets/showcase/data/homefeatures.json')
        .toPromise()
        .then(res => <Homepagefeature[]>res.data)
        .then(data => { return data; });
    }

   

   

    

    generateId() {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        
        return text;
    }

    

    

    

    

    
}