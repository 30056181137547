import { HttpClient, HttpClientJsonpModule, HttpErrorResponse, HttpEventType, HttpHeaders,   HttpParams } from '@angular/common/http';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { Injectable, ɵCodegenComponentFactoryResolver } from '@angular/core';

import {Component,OnInit,OnChanges} from '@angular/core';
import { Observable } from 'rxjs';
import { Game } from '../domain/games';
//import { Calendar } from 'primeng/calendar';

import { ConfirmationService, MessageService } from 'primeng/api';







@Injectable()
export class GameService implements OnInit {

    

   
   
   
    constructor(private http: HttpClient ) { }
   
   

    ngOnInit() {
   
    };


    

 




        async saveGames(game :Game) {

        
       

           const headers  = new HttpHeaders({
            'Accept': 'application/json',
            //'enctype': 'multipart/form-data',
            'Access-Control-Allow-Origin' : 'http://localhost:4200',
            'Content-Type': 'application/json'
           // 'Content-Type': 'application/x-www-form-urlencoded'
            
        });
        
    
        const body = JSON.stringify(game,);
        console.log(body);
  
     
        return  await  this.http
        .post<Game>('http://www.pokerprobot.com/wrestling/Games/creategames.php/', body , {headers})
        .toPromise()
        .then(data => { return data.id;} )  
                
          

            

      };

      async updateGames(game :Game) {

        
       

        const headers  = new HttpHeaders({
         'Accept': 'application/json',
         //'enctype': 'multipart/form-data',
         'Access-Control-Allow-Origin' : 'http://localhost:4200',
         'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded'
         
     });
     
     
     const body = JSON.stringify(game,);
     console.log(body);

     
     return await  this.http
         .post<Game>('http://www.pokerprobot.com/wrestling/Games/updategames.php/', body , {headers})
         .subscribe (data => data ,
         
         (err: HttpErrorResponse) => {
          console.log('Error occurred. Details: ' + err.name + ' ' + err.message, 8000);
        })
         
         
       

         

   };


   async deleteGames(game :Game) {

        
       

    const headers  = new HttpHeaders({
     'Accept': 'application/json',
     //'enctype': 'multipart/form-data',
     'Access-Control-Allow-Origin' : 'http://localhost:4200',
     'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded'
     
 });
 
 
 const body = JSON.stringify(game);
 console.log(body);
const options = {headers : headers, body: body}
 
  return await this.http
   .post<Game>('http://www.pokerprobot.com/wrestling/Games/deletegames.php/',  body, {headers})
   .subscribe(data => data,

     (err: HttpErrorResponse) => {
       console.log('Error occurred. Details: ' + err.name + ' ' + err.message, 8000);
     })
     
     
   

     

};




      async getGames( )  {
          
        return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Games/games.php')
        .toPromise()
        .then(res => <any[]>res.data)
       .then(data => { return data;} )
        };
       
      
 
    async getGamebyID(gameid : string)  {
      return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Games/games.php?gameid=${gameid}')
      .toPromise()
      .then(res => <any[]>res.data)
     .then(data => { return data;} )
      };
    
       
  };
  

