import { Component, Injectable, OnDestroy, OnInit,NgModule } from '@angular/core';
import { AppConfigService } from '../../service/appconfigservice';
import { AppConfig } from '../../domain/appconfig';
import { Subscription } from 'rxjs';

import { HomePageFeatureService } from '../../service/homepagefeatureservice';
import { Homepagefeature } from '../../domain/homepagefeature';





@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})


export class HomeComponent implements OnInit, OnDestroy {
  
    title = "test"
    config: AppConfig;

    subscription: Subscription;

    homepagefeatures: Homepagefeature[];
    responsiveOptions;  
	
    /* , private homepagefeatureService: HomePageFeatureService */
    constructor(private configService: AppConfigService, private homepagefeatureService: HomePageFeatureService) {

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    ngOnInit() {
        this.homepagefeatureService.getFeatures().then(homepagefeatures => {
            this.homepagefeatures = homepagefeatures;
        this.config = this.configService.config;
        
        this.subscription = this.configService.configUpdate$.subscribe(config => this.config = config);
         
        });
       
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    }
    
    

