import { HttpClient, HttpClientJsonpModule, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { EventInput, EventSourceInput } from '@fullcalendar/angular';
import {Component,OnInit,OnChanges} from '@angular/core';
//import { Calendar } from 'primeng/calendar';
import {Calendar} from '../domain/calendar'
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';








@Injectable()
export class CalendarService implements OnInit {

    events1: EventSourceInput;

     headerDict = {
      'Content-Type': 'application/json',
    
      'Accept': 'application/json; text/html; application/xhtml+xml; application/xml',
      
    }
    
     requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(this.headerDict), 
    };
    


   

   
   
    constructor(private http: HttpClient) { }
   
    //constructor(private http: HttpClient) { };

    ngOnInit() {
      this.getTournamentEvents()
    };


    getTest (){
      return "tets";
    }

     async getTournamentEvents( )  {
          
        return await this.http.get<any>('assets/showcase/data/tournamentevents.json')
        .toPromise()
        .then(res => <any[]>res.data)
        .then(data => {return data.filter(e => (<Calendar>e).groupId == "TOURNAMENTS") ;});
        
       
      }
 
    async getPlannerEvents( ) {
      return await this.http.get<any>('assets/showcase/data/tournamentevents.json')
        .toPromise()
        .then(res => <any[]>res.data)
        .then(data => {return data.filter(e => (<Calendar>e).groupId == "PRACTICE") ;});
      };
    

      

      async getAllEvents( )    {
       return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Calendar/calendarevents.php')
       .toPromise()
       .then(res => <any[]>res.data)
      .then(data => { return data;} )
      //.catch(err => console.error(err));
      }   
       
     
    
       /*  const response = await fetch( 'http://pokerprobot.com/wrestling/test.php?groupid=1')
        const data = await response;
        //return data;
        console.log(data) */
      //}

     // private api<T>(url: string): Promise<T> {

      /* private (url: string)  {
        return fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json().then(data => data as T);
          })           */
   



  

     /*  async getAllEvents( )  {

         this.getAllEvents2();
         return await this.http.get<any>('pokerprobot.com/wrestling/test.php?groupid=1')
        .toPromise()
        .then(res => <any[]>res.data)
        .then( data => {return data})  
      } */


      










       
  };
  

