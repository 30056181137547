import { Component, EventEmitter, Output, ViewChild, ElementRef, Input, OnInit, OnDestroy, NgModule } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from './service/appconfigservice';
import { VersionService } from './service/versionservice';
import { AppConfig } from './domain/appconfig';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api/menuitem';
import { CalendarEventTypesService } from './service/calendareventtypesservice';
import { ResolvedStaticSymbol } from '@angular/compiler';


/* <div class="app-theme" [pTooltip]="config.theme" tooltipPosition="bottom">
<img [src]="'assets/showcase/images/themes/' + logoMap[config.theme]" />
</div> */

/* <div class="layout-topbar">
       s
<a [routerLink]="['/']" class="logo">
    <img alt="logo" [src]="'assets/GivensWrestling/images/home/' +
            (config.dark ?  'LogoforDark.png' : 'LogoforLight.png')" />
</a> */


@Component({
    selector: 'app-topbar',
    template: 
    `
   
    <div class = "p-grid">
    <div class="p-col" style="width:100px">
    <a [routerLink]="['/']" class="logo">
    <img height="50px" alt="logo" [src]="'assets/GivensWrestling/images/home/RHHS_Logo_HawkHead.png'" />
    </a> 
   </div>
    <div class="p-col" style="width:auto">
    <h3> Rock Hill Wrestling Academy </h3>
    </div>
    
    </div>   
   
    <p-menubar [model]="items" [style]="{'width': '100%','height' : '25px'}" ></p-menubar>
      
    `,
    animations: [
        trigger('overlayMenuAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 5, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ]
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    @Output() menuButtonClick: EventEmitter<any> = new EventEmitter();

    @ViewChild('topbarMenu') topbarMenu: ElementRef;

    activeMenuIndex: number;

    outsideClickListener: any;

    config: AppConfig;

    subscription: Subscription;
   
    versions: any[];
    items: MenuItem[];


    
    constructor(private router: Router, private versionService: VersionService, private configService: AppConfigService ) {}


    async ngOnInit() {

      /* .filter(route => route.data && route.data.title) //only add a menu item for routes with a title set.
      .map(route => {
          return {
              path: route.path,
              title: route.data.title,
              icon: route.data.icon
          };
      });
      
      
         await this.eventtypeService.getEventTypes()
               .then(retval => retval
                  .filter( e=> e.path && e.title)
               
                     return path : retva */



        this.items = [
            {
               label:'File',
               icon:'pi pi-fw pi-file',
               items:[
                  {

                     label:'New',
                     icon:'pi pi-fw pi-plus',
                     items:[
                        {
                           label:'Bookmark',
                           icon:'pi pi-fw pi-bookmark',
                           routerLink: '/tournaments'
                        },
                        {
                           label:'Video',
                           icon:'pi pi-fw pi-video'
                        },

                     ]
                  },
                  {
                     label:'Edit Stuff',
                     icon:'pi pi-fw pi-trash',
                     routerLink: '/editstuff'
                  },
                  {
                     separator:true
                  },
                  {
                     label:'Export',
                     icon:'pi pi-fw pi-external-link'
                  }
               ]
            },
            {
               label:'Setup',
               icon:'pi pi-fw pi-pencil',
               items:[
                  {
                     label:'Lookup Lists',
                     icon:'pi pi-fw pi-align-left',
                     items:[
                        {
                           label:'Technique Types',
                           icon:'pi pi-fw pi-align-left',
                           routerLink:'/techniquetypeeditor' // ,'Techniques']
                        },
                        {
                           label:'Calendar Type Events',
                           icon:'pi pi-fw pi-align-left',
                           routerLink:'/calendartypeeditor'
                        },
                        {
                           label:'Games',
                           icon:'pi pi-fw pi-align-left',
                           routerLink:'/gametypeeditor'
                        }
                     ]
                  },
                  {
                     label:'Technique',
                     icon:'pi pi-fw pi-align-right',
                     routerLink:['/editstuff','technique']
                  },
                  {
                     label:'Coaches',
                     icon:'pi pi-fw pi-align-justify',
                     routerLink:['/editstuff','coaches']
                  },
                  {
                     label:'Coaches',
                     icon:'pi pi-fw pi-align-justify',
                     routerLink:['/editstuff','wrestlers']
                  }

               ]
            },
            {
               label:'Users',
               icon:'pi pi-fw pi-user',
               items:[
                  {
                     label:'New',
                     icon:'pi pi-fw pi-user-plus',

                  },
                  {
                     label:'Delete',
                     icon:'pi pi-fw pi-user-minus',

                  },
                  {
                     label:'Search',
                     icon:'pi pi-fw pi-users',
                     items:[
                        {
                           label:'Filter',
                           icon:'pi pi-fw pi-filter',
                           items:[
                              {
                                 label:'Print',
                                 icon:'pi pi-fw pi-print'
                              }
                           ]
                        },
                        {
                           icon:'pi pi-fw pi-bars',
                           label:'List'
                        }
                     ]
                  }
               ]
            },
            {
               label:'Calendar',
               icon:'pi pi-fw pi-calendar',
               routerLink: '/calendar'
               
            },
            {
               separator:true
            },
            {
               label:'Login/Logout',
               icon:'pi pi-fw pi-power-off'
            }
        ];

        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => this.config = config);
        this.versionService.getVersions().then(data => this.versions = data);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.activeMenuIndex = null;
             }
        });
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick.emit();
        event.preventDefault();
    }

    changeTheme(event: Event, theme: string, dark: boolean) {
        let themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.config.theme, theme));
        this.configService.updateConfig({...this.config, ...{theme, dark}});
        this.activeMenuIndex = null;
        event.preventDefault();
    }

    bindOutsideClickListener() {
        if (!this.outsideClickListener) {
            this.outsideClickListener = (event) => {
                if (this.isOutsideTopbarMenuClicked(event)) {
                    this.activeMenuIndex =  null;
                }
            };

            document.addEventListener('click', this.outsideClickListener);
        }
    }

    unbindOutsideClickListener() {
        if (this.outsideClickListener) {
            document.removeEventListener('click', this.outsideClickListener);
            this.outsideClickListener = null;
        }
    }

    toggleMenu(event: Event, index: number) {
        this.activeMenuIndex = this.activeMenuIndex === index ? null : index;
        event.preventDefault();
    }

    isOutsideTopbarMenuClicked(event): boolean {
        return !(this.topbarMenu.nativeElement.isSameNode(event.target) || this.topbarMenu.nativeElement.contains(event.target));
    }

    onOverlayMenuEnter(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.bindOutsideClickListener();
            break;

            case 'void':
                this.unbindOutsideClickListener();
            break;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
