import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="layout-footer-left">
                <span>Powered by </span>
                <a href="https://www.givenswrestling.com">Givens Wrestling</a>
            </div>

            <div class="layout-footer-right">
                <a href="https://twitter.com/GivensWrestling" class="p-mr-3">
                    <i class="pi pi-twitter"></i>
                </a>
                <a href="https://facebook.com/GivensWrestling" class="p-mr-3">
                    <i class="pi pi-facebook"></i>
                </a>
                <a href="https://instagram.com/GivensWrestling" class="p-mr-3">
                    <i class="pi pi-instagram"></i>
                </a>
            </div>
        </div>
    `
})
export class AppFooterComponent {
}