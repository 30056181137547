import { HttpClient, HttpClientJsonpModule, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵCodegenComponentFactoryResolver } from '@angular/core';

import {Component,OnInit,OnChanges} from '@angular/core';
//import { Calendar } from 'primeng/calendar';









@Injectable()
export class TechniqueService implements OnInit {

    

   
   
   
    constructor(private http: HttpClient) { }
   
   

    ngOnInit() {
   
    };


    

     async getTechniqueTypes( )  {
          
      return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Techniques/techniquetypes.php')
      .toPromise()
      .then(res => <any[]>res.data)
     .then(data => { return data;} )
      };








      async getTechniques( )  {
          
        return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Techniques/techniques.php')
        .toPromise()
        .then(res => <any[]>res.data)
       .then(data => { return data;} )
        };
       
      
 
    async getTechniquebyID(techniqueid : string)  {
      return await this.http.get<any>('http://www.pokerprobot.com/wrestling/Techniques/techniques.php?techniqueid=${technuqueid}')
      .toPromise()
      .then(res => <any[]>res.data)
     .then(data => { return data;} )
      };
    
       
  };
  

