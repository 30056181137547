import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-techniques',
  templateUrl: './techniques.component.html',
  styleUrls: ['./techniques.component.scss']
})
export class TechniquesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
