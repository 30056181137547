// Need to add import for services here too as a provider

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import {CardModule} from 'primeng/card'
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';

import { CarService } from './service/carservice';
import { CountryService } from './service/countryservice';
import { CalendarService } from './service/calendarservice';
import { GameService } from './service/gameservice';
import { CalendarEventTypesService } from './service/calendareventtypesservice';
import { TechniqueService } from './service/techniqueservice';
import { NodeService } from './service/nodeservice';

import { IconService } from './service/iconservice';
import { CustomerService } from './service/customerservice';
import { PhotoService } from './service/photoservice';
import { VersionService } from './service/versionservice';
import { AppConfigService } from './service/appconfigservice';
import { ProductService } from './service/productservice';
import {HomePageFeatureService} from './service/homepagefeatureservice';

import { AppNewsComponent } from './app.news.component';
import { AppTopBarComponent } from './app.topbar.component';

import { AppConfigComponent } from './app.config.component';
import { AppFooterComponent } from './app.footer.component';
import { AppInputStyleSwitchModule } from './app.inputstyleswitch.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {CarouselModule } from 'primeng/carousel';
import { PlansComponent } from './components/plans/plans.component';
import { TechniquesComponent } from './components/techniques/techniques.component';
import { DailyplansComponent } from './components/dailyplans/dailyplans.component';
import { PlanCatagoriesComponent } from './components/plan-catagories/plan-catagories.component';
import { TechniqueCatagoriesComponent } from './components/technique-catagories/technique-catagories.component';
import { PlanclassnamesComponent } from './components/planclassnames/planclassnames.component';
import { DatabaseComponent } from './components/database/database.component'
import { AppCodeModule } from './app.code.component';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MenubarModule} from 'primeng/menubar';
import {TabMenuModule} from 'primeng/tabmenu';
import { ToolbarModule } from 'primeng/toolbar';

//import {ListEditor} from 


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AppNewsComponent,
        AppTopBarComponent,
       
        AppConfigComponent,
        AppFooterComponent,
        PlansComponent,
        TechniquesComponent,
        DailyplansComponent,
        PlanCatagoriesComponent,
        TechniqueCatagoriesComponent,
        PlanclassnamesComponent,
        DatabaseComponent
    ],
    imports: [
        
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AutoCompleteModule,
        ButtonModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        AppInputStyleSwitchModule,
        CardModule,
        CarouselModule,
        TieredMenuModule,
        AppCodeModule,
        MenubarModule,
        TabMenuModule,
        ToolbarModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CarService,CountryService,CalendarService, CalendarEventTypesService,GameService,  TechniqueService, NodeService,IconService,CustomerService,PhotoService,VersionService,AppConfigService, ProductService, HomePageFeatureService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
