import {Component, ElementRef, OnInit, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from './service/appconfigservice';
import { AppConfig } from './domain/appconfig';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-config',
    template: `
        <div class="layout-config" [ngClass]="{'layout-config-active': active}">
            <div class="layout-config-content-wrapper">
                <a tabindex="0" class="layout-config-button" (click)="toggleConfigurator($event)" pTooltip="Configure UI">
                    <i class="pi pi-cog"></i>
                </a>
                <a tabindex="0" class="layout-config-close" (click)="hideConfigurator($event)">
                    <i class="pi pi-times"></i>
                </a>

                <div class="layout-config-content">
                    <div>
                        <h4>Adjust Page Scale</h4>
                        <div class="config-scale">
                            <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text" [disabled]="scale === scales[0]"></button>
                            <i class="pi pi-circle-on" *ngFor="let s of scales" [ngClass]="{'scale-active': s === scale}"></i>
                            <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text" [disabled]="scale === scales[scales.length - 1]"></button>
                        </div>
                        
                        <h4>Themes</h4>
                    
                        <div class="p-grid free-themes">
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'bootstrap4-light-blue', false)">
                                    <img src="assets/showcase/images/themes/bootstrap4-light-blue.svg" alt="Bootstrap Light Blue" />
                                </button>
                                <span>Blue</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'bootstrap4-light-purple', false)">
                                    <img src="assets/showcase/images/themes/bootstrap4-light-purple.svg" alt="Bootstrap Light Purple" />
                                </button>
                                <span>Purple</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'bootstrap4-dark-blue', true)">
                                    <img src="assets/showcase/images/themes/bootstrap4-dark-blue.svg" alt="Bootstrap Dark Blue" />
                                </button>
                                <span>Blue</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'bootstrap4-dark-purple', true)">
                                    <img src="assets/showcase/images/themes/bootstrap4-dark-purple.svg" alt="Bootstrap Dark Purple" />
                                </button>
                                <span>Purple</span>
                            </div>
                        </div>

                        <div class="p-grid free-themes">
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'md-light-indigo', false)">
                                    <img src="assets/showcase/images/themes/md-light-indigo.svg" alt="Material Light Indigo" />
                                </button>
                                <span>Indigo</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'md-light-deeppurple', false)">
                                    <img src="assets/showcase/images/themes/md-light-deeppurple.svg" alt="Material Light Deep Purple" />
                                </button>
                                <span>Deep Purple</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'md-dark-indigo', true)">
                                    <img src="assets/showcase/images/themes/md-dark-indigo.svg" alt="Material Dark Indigo" />
                                </button>
                                <span>Indigo</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'md-dark-deeppurple', true)">
                                    <img src="assets/showcase/images/themes/md-dark-deeppurple.svg" alt="Material Dark Deep Purple" />
                                </button>
                                <span>Deep Purple</span>
                            </div>
                        </div>

                        <div class="p-grid free-themes">
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'mdc-light-indigo', false)">
                                    <img src="assets/showcase/images/themes/md-light-indigo.svg" alt="Material Compact Light Indigo"/>
                                </button>
                                <span>Indigo</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'mdc-light-deeppurple', false)">
                                    <img src="assets/showcase/images/themes/md-light-deeppurple.svg" alt="Material Compact Deep Purple" />
                                </button>
                                <span>Deep Purple</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'mdc-dark-indigo', true)">
                                    <img src="assets/showcase/images/themes/md-dark-indigo.svg" alt="Material Compact Dark Indigo" />
                                </button>
                                <span>Indigo</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'mdc-dark-deeppurple', true)">
                                    <img src="assets/showcase/images/themes/md-dark-deeppurple.svg" alt="Material Compact Dark Deep Purple" />
                                </button>
                                <span>Deep Purple</span>
                            </div>
                        </div>

                        <div class="p-grid free-themes">
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'saga-blue', false)">
                                    <img src="assets/showcase/images/themes/saga-blue.png" alt="Saga Blue" />
                                </button>
                                <span>Saga Blue</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'saga-green', false)">
                                    <img src="assets/showcase/images/themes/saga-green.png" alt="Saga Green" />
                                </button>
                                <span>Saga Green</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'saga-orange', false)">
                                    <img src="assets/showcase/images/themes/saga-orange.png" alt="Saga Orange" />
                                </button>
                                <span>Saga Orange</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'saga-purple', false)">
                                    <img src="assets/showcase/images/themes/saga-purple.png" alt="Saga Purple" />
                                </button>
                                <span>Saga Purple</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'vela-blue', true)">
                                    <img src="assets/showcase/images/themes/vela-blue.png" alt="Vela Blue" />
                                </button>
                                <span>Vela Blue</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'vela-green', true)">
                                    <img src="assets/showcase/images/themes/vela-green.png" alt="Vela Green" />
                                </button>
                                <span>Vela Green</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'vela-orange', true)">
                                    <img src="assets/showcase/images/themes/vela-orange.png" alt="Vela Orange" />
                                </button>
                                <span>Vela Orange</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'vela-purple', true)">
                                    <img src="assets/showcase/images/themes/vela-purple.png" alt="Vela Purple" />
                                </button>
                                <span>Vela Purple</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'arya-blue', true)">
                                    <img src="assets/showcase/images/themes/arya-blue.png" alt="Arya Blue" />
                                </button>
                                <span>Arya Blue</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'arya-green', true)">
                                    <img src="assets/showcase/images/themes/arya-green.png" alt="Arya Green" />
                                </button>
                                <span>Arya Green</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'arya-orange', true)">
                                    <img src="assets/showcase/images/themes/arya-orange.png" alt="Arya Orange" />
                                </button>
                                <span>Arya Orange</span>
                            </div>
                            <div class="p-col-3">
                                <button class="p-link" (click)="changeTheme($event, 'arya-purple', true)">
                                    <img src="assets/showcase/images/themes/arya-purple.png" alt="Arya Purple" />
                                </button>
                                <span>Arya Purple</span>
                            </div>
                        </div>



                        
                    </div>
                </div>
            </div>
        </div>
    `
})
export class AppConfigComponent implements OnInit, OnDestroy {

    active: boolean;

    scale: number = 14;
    scales: number[] = [12,13,14,15,16];

    outsideClickListener: any;

    config: AppConfig;

    subscription: Subscription;

    constructor(private el: ElementRef, private router: Router, private configService: AppConfigService) {}

    ngOnInit() {
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => {
            this.config = config;
            if (this.config.theme === 'nano')
                this.scale = 12;
            else
                this.scale = 14;

            this.applyScale();
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.active = false
             }
        });

        if (this.config.theme === 'nano')
            this.scale = 12;
    }

    toggleConfigurator(event: Event) {
        this.active = !this.active;
        event.preventDefault();

        if (this.active)
            this.bindOutsideClickListener();
        else
            this.unbindOutsideClickListener();
    }

    hideConfigurator(event) {
        this.active = false;
        this.unbindOutsideClickListener();
        event.preventDefault();
    }

    changeTheme(event: Event, theme: string, dark: boolean) {
        let themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.config.theme, theme));
        this.configService.updateConfig({...this.config, ...{theme, dark}});
        event.preventDefault();
    }

    onRippleChange() {
        this.configService.updateConfig(this.config);
    }

    bindOutsideClickListener() {
        if (!this.outsideClickListener) {
            this.outsideClickListener = (event) => {
                if (this.active && this.isOutsideClicked(event)) {
                    this.active = false;
                }
            };
            document.addEventListener('click', this.outsideClickListener);
        }
    }

    unbindOutsideClickListener() {
        if (this.outsideClickListener) {
            document.removeEventListener('click', this.outsideClickListener);
            this.outsideClickListener = null;
        }
    }

    isOutsideClicked(event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    decrementScale() {
        this.scale--;
        this.applyScale();
    }

    incrementScale() {
        this.scale++;
        this.applyScale();
    }

    applyScale() {
        document.documentElement.style.fontSize = this.scale + 'px';
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
