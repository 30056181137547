<div class="layout-wrapper" [ngClass]="{'layout-news-active': newsActive, 
            'p-input-filled': config.inputStyle === 'filled', 'p-ripple-disabled': !config.ripple}">
    <app-news *ngIf="newsActive" (onNewsHide)="hideNews()"></app-news>
    <app-topbar (menuButtonClick)="onMenuButtonClick()"></app-topbar>
  
    <app-config></app-config>
    <div class="layout-mask" [ngClass]="{'layout-mask-active': menuActive}" (click)="onMaskClick()"></div>

    <div class="layout-content">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
